<template lang="pug">
  v-menu(
    v-model="menu"
    :close-on-content-click="true"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto")
    template(v-slot:activator="{ on, attrs }")
      v-text-field(
        :value="value"
        @input="change"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on")
    v-date-picker(:value="value" @input="change" :max="new Date().toISOString().substr(0, 10)" first-day-of-week="1")
</template>

<script>
  export default {
    props: ['value', 'label'],
    data: () => ({
      menu: false,
    }),
    methods: {
      changeDate(val) {
        this.menu = false
        this.change(val)
      },
      change(val) {
        this.$emit('input', val)
      }
    }
  }
</script>